var render = function () {
  var _vm$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart"
  }, [_c('div', {
    staticClass: "chart__header"
  }, [_c('p', {
    staticClass: "chart__title"
  }, [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.name))])]), _c('sygni-card', {
    staticClass: "chart__content"
  }, [_c('div', {
    staticClass: "chart__content-inner"
  }, [_c('v-chart', {
    attrs: {
      "option": _vm.options,
      "autoresize": ""
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }