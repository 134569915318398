





































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import breakpoints from '@/plugins/breakpoints';
import { Dictionaries } from '@/modules/genprox/modules/fund/modules/portfolio/store/types';
import { Prop } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniSquareButton, SygniRectButton, SygniModal },
})
export default class StaticReportsTable extends SygniTable<any> {
  @Prop() tableItems: any[];

  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: false, class: 'text-left', label: 'Report Name'},
    { key: 'reportDate', sortable: false, class: 'text-center', label: 'Report Date'},
    { key: 'category', sortable: false, class: 'text-center', label: 'Category'},
    { key: 'actions', sortable: false, class: 'actions', borderless: true, label: 'Actions'},
  ];

  downloadFile(item: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${item?.filePath}`, item?.name || '')
  }

  getCategoryClassName(category: string) {
    switch(category?.toLowerCase()) {
      case 'balance sheet report':
        return 'primary'
      case 'kpi report':
        return 'success'
      case 'other':
        return 'danger'
      default:
        return 'black'
    }
  }
}

