















import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import moment from 'moment';

// echart imports
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { LineChart, BarChart } from 'echarts/charts'
import {
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
} from 'echarts/components'
import { SVGRenderer } from 'echarts/renderers'

use([
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  BarChart,
  LineChart,
  SVGRenderer
])

// @ts-ignore
@Component({
  components: { SygniCard, VChart },
})
export default class NewChart extends Vue {
  @Prop() data: any;

  displayData: [] = [];
  chartData: any = {
    datasets: [{
      fill: 'start',
      label: false,
      borderColor: '#b0b0b0',
      backgroundColor: '#ebebeb',
      data: [],
    }],
  }
  chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        },
        beginAtZero: true,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  }
  chartStyles = {
    height: '100%',
    width: '100%'
  }

  optionsData: any = {}

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  get optionsDataObject(): any {
    if (this.data?.type === 'line') {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          top: '0%'
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '25%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis:{
          type: 'value'
        },
        series: []
      }
    } else if (this.data?.type === 'column') {
      return {
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '25%',
          containLabel: true,
        },
        legend: {
          top: '0%'
        },
        tooltip: {},
        dataset: {
          source: []
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: []
      }
    } else if (this.data?.type === 'stacked-column') {
      return {
        tooltip: {
          trigger: 'item',
          position: 'right',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '0%',
          right: '0%',
          top: '25%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Direct',
          //   type: 'bar',
          //   stack: 'total',
          //   label: {
          //     show: true
          //   },
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [320, 302, 301, 334, 390, 330, 320]
          // },
        ]
      }
    }

    return {}
  }

  get options() {
    const optionsData = this.optionsDataObject

    if (this.data?.type === 'line') {
      if (this.data?.values?.length) {
        this.data?.values?.forEach((item: any) => {
          optionsData.series.push({
            name: item?.name,
            type: 'line',
            stack: `Total ${item?.name}`,
            data: item?.data?.map((el: any) => el ? Number(el) : 0)
          })
        })
      }
  
      if (this.data?.xData?.dates?.length) {
        optionsData.xAxis.data = this.data?.xData?.dates?.map((date: string) => {
          const year: string = moment(date).format('YYYY-M-DD').split('-')[0];
          const monthNumber: any = moment(date).format('YYYY-M-DD').split('-')[1];
  
          return `${this.monthNames[monthNumber - 1]}, ${year}`
        })
      }
    }

    if (this.data?.type === 'column') {

      if (this.data?.values?.length) {
        const datasetHeader = ['date', ...this.data?.values?.map((el: any) => el?.name)]
        optionsData.dataset.source[0] = datasetHeader

        if (this.data?.xData?.dates?.length) {
          const dates = this.data?.xData?.dates?.map((date: string) => {
            const year: string = moment(date).format('YYYY-M-DD').split('-')[0];
            const monthNumber: any = moment(date).format('YYYY-M-DD').split('-')[1];
    
            return `${this.monthNames[monthNumber - 1]}, ${year}`
          })

          dates?.forEach((date: string, i: number) => {
            const values = this.data?.values?.map((el: any) => el?.data[i] ? Number(el?.data[i]) : 0)
            optionsData.dataset.source.push([date, ...values])
          })

          // const datasetHeader = ['name', ...dates]
          // optionsData.dataset.source[0] = datasetHeader
          
          // eslint-disable-next-line no-unused-vars
          this.data?.values?.forEach((item: any) => {
            optionsData.series.push({ type: 'bar' })
          })
        }
      }

    }
    
    if (this.data?.type === 'stacked-column') {
      if (this.data?.xData?.dates?.length) {
        const dates = this.data?.xData?.dates?.map((date: string) => {
          const year: string = moment(date).format('YYYY-M-DD').split('-')[0];
          const monthNumber: any = moment(date).format('YYYY-M-DD').split('-')[1];
  
          return `${this.monthNames[monthNumber - 1]}, ${year}`
        })

        optionsData.xAxis.data.push(...dates)
      }

      if (this.data?.values?.length) {
        this.data?.values?.forEach((item: any) => {
          optionsData.series.push({
            name: item?.name,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [...item?.data]
          })
        })
      }
    }

    return optionsData
  }
}
